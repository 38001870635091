import { takeLatest, put, call, cancel, takeEvery } from 'redux-saga/effects'
import { USER } from '../actions/types'
import {
  getCartSuccessful,
  getCart,
  getFavouritesSuccessful,
  getFavourites,
  applyCoupon,
  getCurrenciesSuccessful
} from '../actions/user'
import {
  loading,
  loadingFinish,
  updatingCart,
  updatingCartFinish
} from '../actions/loading'
import axios from 'axios'
import { toast } from 'react-toastify'
import _ from 'lodash'

export const user = (state) => state.user

const baseURL =
  process.env.REACT_APP_PROD_MODE === 'test'
    ? process.env.REACT_APP_API_URL_TEST
    : process.env.REACT_APP_API_URL_LIVE

function * handleGetFavourites () {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token
  const apiCall = async () => {
    try {
      return await axios.get(`${baseURL}/favourites/get`, {
        headers: {
          'x-auth-token': token
        }
      })
    } catch (error) {
      throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      yield put(getFavouritesSuccessful(response.data.favourites))
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(loadingFinish())
  }
}

function * handleGetCart () {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token

  const apiCall = async () => {
    try {
      return await axios.get(`${baseURL}/cart/get`, {
        headers: {
          'x-auth-token': token
        }
      })
    } catch (error) {
      throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      const data = {
        total_price: response.data.cummulativeTotalPrice,
        items: [...response.data.shoppingBag]
      }
      yield put(getCartSuccessful(data))
    }
  } catch (error) {
    if (error?.response?.data?.message && !user.isLoggedIn) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(loadingFinish())
  }
}

function * handleAddFavourite ({ payload }) {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token

  const apiCall = async () => {
    try {
      return await axios.post(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/favourites/create`,
        { productId: [payload] },
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 200 || response.status === 201) {
      toast.success(response.data.message)
      yield put(getFavourites())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  }
}

function * handleAddItem ({ payload }) {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token
  yield put(updatingCart())

  if (!token) {
    let cart = []
    const contains = false

    if (sessionStorage.getItem('cart')) {
      cart = [...JSON.parse(sessionStorage.getItem('cart'))]

      if (cart.some(
        item =>
          item.variationName === payload[0].variationName &&
          item.productId === payload[0].productId
      )
      ) {
        const itemIndex = cart.findIndex(
          item =>
            item.variationName === payload[0].variationName &&
            item.productId === payload[0].productId
        )

        cart[itemIndex].noItems += parseInt(payload[0].noItems)
        sessionStorage.setItem('cart', JSON.stringify(cart))

        yield put(
          getCartSuccessful({
            total_price: JSON.parse(sessionStorage.getItem('cart')).reduce(
              (sum, item) =>
                sum + parseInt(item.productPrice) * parseInt(item.noItems),
              0
            ),
            items: JSON.parse(sessionStorage.getItem('cart'))
          })
        )

        yield put(updatingCartFinish())
        toast.success('Successfully added to cart')

        return
      } else if (
        cart.some(
          item =>
            item.variationName !== payload[0].variationName &&
        item.productId === payload[0].productId
        )) {
        payload[0].noItems = parseInt(payload[0].noItems)

        cart.push(payload[0])
        sessionStorage.setItem('cart', JSON.stringify(cart))

        yield put(
          getCartSuccessful({
            total_price: JSON.parse(sessionStorage.getItem('cart')).reduce(
              (sum, item) =>
                sum + parseInt(item.productPrice) * parseInt(item.noItems),
              0
            ),
            items: JSON.parse(sessionStorage.getItem('cart'))
          })
        )

        yield put(updatingCartFinish())
        toast.success('Successfully added to cart')

        return
      } else if (
        cart.some(
          item =>
            item.variationName !== payload[0].variationName &&
            item.productId !== payload[0].productId
        )) {
        payload[0].noItems = parseInt(payload[0].noItems)

        cart.push(payload[0])
        sessionStorage.setItem('cart', JSON.stringify(cart))

        yield put(
          getCartSuccessful({
            total_price: JSON.parse(sessionStorage.getItem('cart')).reduce(
              (sum, item) =>
                sum + parseInt(item.productPrice) * parseInt(item.noItems),
              0
            ),
            items: JSON.parse(sessionStorage.getItem('cart'))
          })
        )

        yield put(updatingCartFinish())
        toast.success('Successfully added to cart')

        return
      } else if (
        cart.some(
          item =>
            item.variationName === undefined && payload[0].variationName === undefined &&
            item.productId !== payload[0].productId
        )) {
        payload[0].noItems = parseInt(payload[0].noItems)

        cart.push(payload[0])
        sessionStorage.setItem('cart', JSON.stringify(cart))

        yield put(
          getCartSuccessful({
            total_price: JSON.parse(sessionStorage.getItem('cart')).reduce(
              (sum, item) =>
                sum + parseInt(item.productPrice) * parseInt(item.noItems),
              0
            ),
            items: JSON.parse(sessionStorage.getItem('cart'))
          })
        )

        yield put(updatingCartFinish())
        toast.success('Successfully added to cart')

        return
      }
    } else {
      cart.push({
        productId: payload[0].productId,
        productPrice: payload[0].productPrice,
        noItems: parseInt(payload[0].noItems),
        productImage: payload[0].productImage,
        productName: payload[0].productName,
        productQuantity: payload[0].productQuantity,
        variationName: payload[0].variationName
      })
      sessionStorage.setItem('cart', JSON.stringify(cart))
      yield put(
        getCartSuccessful({
          total_price: JSON.parse(sessionStorage.getItem('cart')).reduce(
            (sum, item) =>
              sum + parseInt(item.productPrice) * parseInt(item.noItems),
            0
          ),
          items: JSON.parse(sessionStorage.getItem('cart'))
        })
      )
      yield put(updatingCartFinish())
      toast.success('Successfully added to cart')
      return
    }
  }

  const apiCall = async () => {
    try {
      return await axios.post(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/cart/add`,
        { payload },

        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 201) {
      toast.success(response.data.message || 'Successfully added to cart')
      yield put(getCart())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(updatingCartFinish())
  }
}

function * handleRemoveItem ({ payload }) {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token

  yield put(updatingCart())

  // implementing offline feature - remove item from cart
  if (!token) {
    const cart = JSON.parse(sessionStorage.getItem('cart'))
    cart.splice(
      _.findIndex(cart, item => item.productId === payload),
      1
    )

    sessionStorage.setItem('cart', JSON.stringify(cart))

    yield put(
      getCartSuccessful({
        total_price: JSON.parse(sessionStorage.getItem('cart')).reduce(
          (sum, item) =>
            sum + parseInt(item.productPrice) * parseInt(item.noItems),
          // item.price,
          0
        ),
        items: JSON.parse(sessionStorage.getItem('cart'))
      })
    )
    if (cart.length === 0) {
      sessionStorage.removeItem('cart')
    }
    yield put(updatingCartFinish())
    toast.success('Successfully removed item from cart')
    return
  }

  const apiCall = async () => {
    try {
      return await axios.delete(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/cart/remove/${payload}`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 200) {
      toast.success(response.data.message)
      yield put(getCart())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(updatingCartFinish())
  }
}

function * handleClearCart () {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token
  yield put(updatingCart())

  const apiCall = async () => {
    try {
      return await axios.delete(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/cart/empty`,
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 200) {
      toast.success(response.data.message)
      yield put(getCart())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(updatingCartFinish())
  }
}

function * handleUpdateItem ({ payload }) {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token
  yield put(updatingCart())

  if (!token) {
    let cart
    if (sessionStorage.getItem('cart')) {
      cart = [...JSON.parse(sessionStorage.getItem('cart'))]

      const index = _.findIndex(
        cart,
        item =>
          item.productId === payload.product.productId &&
          item.variationName === payload.product.variationName
      )

      cart[index] = {
        productId: payload.product.productId,
        productName: payload.product.productName,
        productImage: payload.product.productImage,
        // price: payload.product.productPrice * payload.product.noItems,
        productPrice: payload.product.productPrice,
        noItems: payload.product.noItems,
        productQuantity: payload.product.productQuantity,
        variationName: payload.product.variationName
      }
      sessionStorage.setItem('cart', JSON.stringify(cart))
    }

    yield put(
      getCartSuccessful({
        total_price: JSON.parse(sessionStorage.getItem('cart')).reduce(
          (sum, item) =>
            sum + parseInt(item.productPrice) * parseInt(item.noItems),

          // item.price,
          0
        ),
        items: JSON.parse(sessionStorage.getItem('cart'))
      })
    )
    yield put(updatingCartFinish())
    toast.success('Successfully added to cart')
    return
  }

  const apiCall = async () => {
    try {
      return await axios.post(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/cart/update_cart/${payload.product._id}`,
        { itemNo: payload.itemNo },
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw error
    }
  }

  try {
    const response = yield call(apiCall)

    if (response.status === 201) {
      toast.success(response.data.message)
      yield put(getCart())
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(updatingCartFinish())
  }
}

function * handleApplyCoupon ({ payload }) {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token
  const cart = payload
  let i
  let itemIndex
  yield put(loading())

  const apiCall = async () => {
    try {
      return await axios.post(
        `${
          process.env.REACT_APP_PROD_MODE === 'test'
            ? process.env.REACT_APP_API_URL_TEST
            : process.env.REACT_APP_API_URL_LIVE
        }/coupon/verifyCoupon`,
        {
          couponCode: payload.couponCode
        },
        {
          headers: {
            'x-auth-token': token
          }
        }
      )
    } catch (error) {
      throw Error
    }
  }

  try {
    const res = yield call(apiCall)

    if (res.status === 200) {
      toast.success('Voucher code is valid')
    }

    if (
      cart.enteredCoupons !== undefined &&
      cart.enteredCoupons.includes(payload.couponCode)
    ) {
      return toast.error('This voucher has been used on a product in this cart')
    }

    const { valueInPercentage, tagAssociated } = res.data.verifiedCouponCode

    const couponCodeDetails = res.data.verifiedCouponCode

    for (i = 0; i < cart.items.length; i++) {
      if (
        cart.items[i].tags.includes(tagAssociated) &&
        cart.items[i].couponPrice !== 0
      ) {
        itemIndex = i
        break
      } else if (tagAssociated === 'halfPrice') {
        cart.items.forEach((item, index) => {
          if (item.tags.includes('shisha') === false) {
            cart.items[index] = {
              ...cart.items[index],
              couponPrice:
              cart.items[index].noItems * cart.items[index].price -
              (cart.items[index].price * cart.items[index].noItems) * parseInt(valueInPercentage) / 100
            }

            cart.total_price =
          cart.total_price -
          (cart.items[index].price * cart.items[index].noItems) * parseInt(valueInPercentage) / 100

            cart.numOfCouponUsed = 1
            cart.enteredCoupons = [cart.couponCode]
            cart.couponUsed = true
            couponCodeDetails.index = index
            couponCodeDetails.discountAmount = (cart.items[index].price * cart.items[index].noItems) * parseInt(valueInPercentage) / 100
            cart.usedVouchers = [couponCodeDetails]
          }
        })

        yield put(getCartSuccessful(cart))
        toast.success('Voucher code applied')
        return
      }
    }

    if (itemIndex === undefined) {
      return toast.error(
        `There is no ${tagAssociated} product to apply the voucher code to in the cart`
      )
    }

    yield put(updatingCart())

    if (cart.items[itemIndex].couponPrice && cart.couponUsed) {
      cart.items[itemIndex] = {
        ...cart.items[itemIndex],
        couponPrice:
          cart.items[itemIndex].couponPrice -
          (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100
      }

      cart.total_price =
        cart.total_price -
        (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100

      cart.amountSaved += cart.items[itemIndex].price - 10000
      cart.numOfCouponUsed += 1
      cart.enteredCoupons = [...cart.enteredCoupons, cart.couponCode]
      couponCodeDetails.index = itemIndex
      couponCodeDetails.discountAmount =
        (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100
      cart.usedVouchers = [...cart.usedVouchers, couponCodeDetails]

      yield put(getCartSuccessful(cart))
      toast.success('Voucher code applied')
    } else if (
      cart.couponUsed &&
      cart.items[itemIndex].couponPrice === undefined
    ) {
      cart.items[itemIndex] = {
        ...cart.items[itemIndex],
        couponPrice:
          cart.items[itemIndex].noItems * cart.items[itemIndex].price -
          (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100
      }

      cart.total_price =
        cart.total_price -
        (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100

      cart.amountSaved += cart.items[itemIndex].price - 10000
      cart.numOfCouponUsed += 1
      cart.enteredCoupons = [...cart.enteredCoupons, cart.couponCode]
      couponCodeDetails.index = itemIndex
      couponCodeDetails.discountAmount =
        (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100
      cart.usedVouchers = [...cart.usedVouchers, couponCodeDetails]

      yield put(getCartSuccessful(cart))
      toast.success('Voucher code applied')
    } else {
      cart.items[itemIndex] = {
        ...cart.items[itemIndex],
        couponPrice:
          cart.items[itemIndex].noItems * cart.items[itemIndex].price -
          (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100
      }

      cart.total_price =
        cart.total_price -
        (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100

      cart.amountSaved = cart.items[itemIndex].price - 10000

      cart.numOfCouponUsed = 1
      cart.enteredCoupons = [cart.couponCode]
      cart.couponUsed = true
      couponCodeDetails.index = itemIndex
      couponCodeDetails.discountAmount =
        (cart.items[itemIndex].price * parseInt(valueInPercentage)) / 100
      cart.usedVouchers = [couponCodeDetails]

      yield put(getCartSuccessful(cart))
      toast.success('Voucher code applied')
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(updatingCartFinish())
    yield put(loadingFinish())
  }
}

function * handleRemoveCoupon ({ payload }) {
  const cart = payload
  try {
    yield put(updatingCart())

    const removeCouponDetails = cart.usedVouchers[cart.removeCouponIndex]

    cart.total_price = cart.total_price + removeCouponDetails.discountAmount
    cart.amountSaved =
      cart.amountSaved - (cart.items[removeCouponDetails.index].price - 10000)

    const removeCouponIndex = cart.enteredCoupons.findIndex(
      coupon => coupon === removeCouponDetails.couponCode
    )

    cart.items[removeCouponDetails.index] = {
      ...cart.items[removeCouponDetails.index],
      couponPrice:
        cart.items[removeCouponDetails.index].price *
          cart.items[removeCouponDetails.index].noItems ===
        cart.items[removeCouponDetails.index].couponPrice +
          cart.items[removeCouponDetails.index].price
          ? undefined
          : cart.items[removeCouponDetails.index].noItems *
            cart.items[removeCouponDetails.index].price
    }

    cart.usedVouchers.splice(removeCouponIndex, 1)
    cart.enteredCoupons.splice(removeCouponIndex, 1)
    cart.removeCouponIndex = ''
    yield put(getCartSuccessful(cart))
    toast.success('Voucher code remove')
  } catch (error) {
  } finally {
    yield put(updatingCartFinish())
    yield put(loadingFinish())
  }
}

function * handleApplyDiscount ({ payload }) {
  const token = JSON.parse(sessionStorage.getItem('user'))?.token
  const cart = payload
  let shisha = 0
  const oldTotalPrice = cart.total_price

  yield put(updatingCart())

  if (token) {
    cart.items.forEach((item) => {
      if (item.tags.includes('shisha')) {
        shisha += item.noItems
      }
    })

    if (shisha >= 10) {
      cart.items.forEach((item) => {
        if (item.tags.includes('shisha')) {
          item.price = 5000
        }
      })

      cart.elfbergDiscountApplied = true

      cart.total_price = cart.items.reduce(
        (sum, item) =>
          sum + parseInt(item.price) * parseInt(item.noItems),
        0
      )

      cart.old_total_price = oldTotalPrice
      yield put(getCartSuccessful(cart))
      toast.success('Elfberg group discount has been applied')
      yield put(updatingCartFinish())
      yield put(loadingFinish())
    } else {
      yield put(getCartSuccessful(cart))
      yield put(updatingCartFinish())
      yield put(loadingFinish())
    }
  }
}

function * handleGetCurrencies () {
  const apiCall = async () => {
    try {
      return await axios.get(`${baseURL}/foreign-currency/get/dollars`)
    } catch (error) {
      throw error
    }
  }

  try {
    yield put(loading())

    const response = yield call(apiCall)

    if (response.status === 200) {
      yield put(getCurrenciesSuccessful(response.data.foreignCurrency))
    }
  } catch (error) {
    if (error?.response?.data?.message) {
      toast.error(error.response.data.message)
    }
  } finally {
    yield put(loadingFinish())
  }
}

const authSaga = [
  takeLatest(USER.ADD_FAVOURITE, handleAddFavourite),
  takeLatest(USER.ADD_ITEM, handleAddItem),
  takeLatest(USER.GET_FAVOURITES, handleGetFavourites),
  takeLatest(USER.GET_CART, handleGetCart),
  takeLatest(USER.REMOVE_ITEM, handleRemoveItem),
  takeLatest(USER.CLEAR_CART, handleClearCart),
  takeLatest(USER.UPDATE_ITEM, handleUpdateItem),
  takeLatest(USER.APPLY_COUPON, handleApplyCoupon),
  takeLatest(USER.REMOVE_COUPON, handleRemoveCoupon),
  takeLatest(USER.APPLY_DISCOUNT, handleApplyDiscount),
  takeLatest(USER.GET_CURRENCY, handleGetCurrencies)
]

export default authSaga
